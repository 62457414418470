import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ApiUrlService {
    // @TODO: Ignored with eslint-interactive on 2023-10-13
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    // public getErpApiUrl(path?: string, version: number = 1): string {
    //     //http://localhost:9090/booking/patient/patient

    //     if (!path) {
    //         path = '';
    //     }
    //     return (
    //         environment?.erp[`v${version}`]?.protocol +
    //         environment?.erp[`v${version}`]?.rootUrl +
    //         environment?.erp[`v${version}`]?.port +
    //         environment?.erp[`v${version}`]?.url +
    //         '/' +
    //         path
    //     );
    // }
    getContentHubApi(parameter?: string): string {
        return environment.contentHub.protocol + environment.contentHub.rootUrl + `${parameter || ''}`;
    }

    getAuthUrl(): string {
        //'http://10.50.40.210:8080/auth/oauth/token
        return environment.auth.protocol + environment.auth.rootUrl + environment.auth.port + environment.auth.url;
    }

    getAdminUrl(params?: string): string {
        let url = environment.admin.protocol + environment.admin.rootUrl + environment.admin.port + environment.admin.url;

        if (params) {
            url += params;
        }
        return url;
    }
    getCaptchaUrl(): string {
        return environment.auth.protocol + environment.auth.rootUrl + environment.auth.port + environment.captcha.url;
    }

    getEambulantaApi(version: number = 1, path?: string): string {
        if (!path) {
            path = '';
        }

        switch (version) {
            case 1:
                return (
                    environment.ea['v1'].protocol + environment.ea['v1'].rootUrl + environment.ea['v1'].port + environment.ea['v1'].url + '/' + path
                );

            case 2:
                return (
                    environment.ea['v2'].protocol + environment.ea['v2'].rootUrl + environment.ea['v2'].port + environment.ea['v2'].url + '/' + path
                );

            default:
                return '';
        }
    }

    getAssistanceUrl(version: number = 1, path?: string): string {
        if (!path) {
            path = '';
        }

        switch (version) {
            case 1:
                return (
                    environment.assistance['v1'].protocol +
                    environment.assistance['v1'].rootUrl +
                    environment.assistance['v1'].port +
                    environment.assistance['v1'].url +
                    '/' +
                    path
                );

            case 2:
                return (
                    environment.assistance['v2'].protocol +
                    environment.assistance['v2'].rootUrl +
                    environment.assistance['v2'].port +
                    environment.assistance['v2'].url +
                    '/' +
                    path
                );

            case 3:
                return (
                    environment.assistance['v3'].protocol +
                    environment.assistance['v3'].rootUrl +
                    environment.assistance['v3'].port +
                    environment.assistance['v3'].url +
                    '/' +
                    path
                );
            default:
                return '';
                break;
        }
    }

    getAssistancRooteUrl(version: number = 3, path?: string): string {
        if (!path) {
            path = '';
        }

        switch (version) {
            // case 1:
            //     return (
            //         environment.assistance['v1'].protocol +
            //         environment.assistance['v1'].rootUrl +
            //         environment.assistance['v1'].port +
            //         environment.assistance['v1'].url +
            //         '/' +
            //         path
            //     );

            // case 2:
            //     return (
            //         environment.assistance['v2'].protocol +
            //         environment.assistance['v2'].rootUrl +
            //         environment.assistance['v2'].port +
            //         environment.assistance['v2'].url +
            //         '/' +
            //         path
            //     );

            case 3:
                return (
                    environment.assistanceRoot['v3'].protocol +
                    environment.assistanceRoot['v3'].rootUrl +
                    environment.assistanceRoot['v3'].port +
                    environment.assistanceRoot['v3'].url +
                    '/' +
                    path
                );
            default:
                return '';
                break;
        }
    }

    public getRootApiUrl(version: number = 1, path?: string): string {
        //http://localhost:9090/booking/

        if (!path) {
            path = '';
        }

        switch (version) {
            case 1:
                return (
                    environment.root['v1'].protocol +
                    environment.root['v1'].rootUrl +
                    environment.root['v1'].port +
                    environment.root['v1'].url +
                    '/' +
                    path
                );
            case 2:
                return (
                    environment.root['v2'].protocol +
                    environment.root['v2'].rootUrl +
                    environment.root['v2'].port +
                    environment.root['v2'].url +
                    '/' +
                    path
                );
            case 3:
                return (
                    environment.root['v3'].protocol +
                    environment.root['v3'].rootUrl +
                    environment.root['v3'].port +
                    environment.root['v3'].url +
                    '/' +
                    path
                );
            default:
                return '';
        }
        // return (
        //     environment?.root?.protocol +
        //     environment?.root?.rootUrl +
        //     environment?.root?.port +
        //     environment?.root?.url +
        //     '/' +
        //     path
        // );
    }

    getNetworkUrl(version: number = 1, path?: string): string {
        if (!path) {
            path = '';
        }
        switch (version) {
            case 1:
                return (
                    environment.network['v1'].protocol +
                    environment.network['v1'].rootUrl +
                    environment.network['v1'].port +
                    environment.network['v1'].url +
                    '/' +
                    path
                );
                break;
            // case 2:
            //     v = 'v2';
            //     break;
            // case 3:
            //     v = 'v3';
            //     break;
            default:
                return '';
                break;
        }
    }

    getOIMUrl(): string {
        return this.getRootApiUrl(3, `eav2/oim/api/`);
    }

    getDocumentUrl(path?: string): string {
        if (!path) {
            path = '';
        }
        return this.getRootApiUrl(2, `erp/docapi/${path}`);
    }
    // getPrintServerUrl(): string {
    //     //http://localhost:9090/booking/papi
    //     return (
    //         environment.printserver.protocol +
    //         environment.printserver.rootUrl +
    //         environment.printserver.port +
    //         environment.printserver.url
    //     );
    // }
}
