import { Selector } from '@ngxs/store';
import { FiredAction } from '../../../_modules/case-statistics/cas-separated/model/firedAction.model';
import { BsGuiSettings } from './../../model/bs-gui-settings';
import { BusinessSubject } from './../../model/business-subject';
import { Contractor } from './../../model/contractor.model';
import { Module } from './../../model/module.model';
import { User } from './../../model/user';
import { BaseStateModel } from './base-state.model';
import { BaseState } from './base.state';
export class BaseSelectors {
    @Selector([BaseState])
    public static getToken(state: BaseStateModel): string | undefined {
        return state.token?.accessToken;
    }

    @Selector([BaseState])
    public static getRefreshToken(state: BaseStateModel): string | undefined {
        return state.token?.refreshToken;
    }

    @Selector([BaseState])
    public static getBS(state: BaseStateModel): BusinessSubject {
        return state?.activeBS || { id: -1 };
    }

    @Selector([BaseState])
    public static getBSId(state: BaseStateModel): number {
        return state?.activeBS?.id || -1;
    }

    @Selector([BaseState])
    public static getUser(state: BaseStateModel): User | undefined {
        return state?.user;
    }

    @Selector([BaseState])
    public static getBsGuiSettings(state: BaseStateModel): BsGuiSettings | undefined {
        return state?.bsGuiSettings;
    }

    @Selector([BaseState])
    public static getContractors(state: BaseStateModel): Contractor[] {
        return state.contractors;
    }

    @Selector([BaseState])
    public static getSelectedContractor(state: BaseStateModel): Contractor {
        return <Contractor>state.selectedContractor;
    }

    @Selector([BaseState])
    public static getSelectedContractorId(state: BaseStateModel): number {
        return <number>state.selectedContractor?.id;
    }

    @Selector([BaseState])
    public static GetActiveModules(state: BaseStateModel): Module[] {
        return state.appModules;
    }

    @Selector([BaseState])
    public static getLanguage(state: BaseStateModel): string | undefined {
        return state.language;
    }
    @Selector([BaseState])
    public static getAllBsRoles(state: BaseStateModel): { [id: number]: string[] } {
        return state?.token?.sdata?.bsRoles || [];
    }
    @Selector([BaseState])
    public static getAllFiredActions(state: BaseStateModel): FiredAction[] {
        return state?.firedActions;
    }
}
