import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { AvaliabilityGroupPipe } from './avaliability-group.pipe';
import { CidToCnamePipe } from './cid-to-cname.pipe';
import { MfCurrencyPipe } from './mf-currency.pipe';
import { MomentDatePipe } from './moment-date.pipe';
import { MonthNamePipe } from './month-name.pipe';
import { SafePipe } from './safe.pipe';
import { TruncatePipe } from './truncate.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [SafePipe, CidToCnamePipe, MonthNamePipe, MomentDatePipe, TruncatePipe, AvaliabilityGroupPipe, MfCurrencyPipe],
    providers: [CurrencyPipe],
    exports: [SafePipe, CidToCnamePipe, MonthNamePipe, MomentDatePipe, TruncatePipe, AvaliabilityGroupPipe, MfCurrencyPipe],
})
export class PipesModule {}
