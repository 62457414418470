import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken } from '@ngxs/store';
import { BaseStateModel } from './base-state.model';
import { BaseStateActions } from './base.actions';

const BASESTATE_TOKEN: StateToken<BaseStateModel> = new StateToken('basestate');

const DEFAULT_STATE: BaseStateModel = {
    token: undefined,
    bsGuiSettings: undefined,
    user: undefined,
    contractors: [],
    selectedContractor: undefined,
    contractorGuiSettings: undefined,
    appModules: [],
    language: undefined,
    firedActions: [],
};

@State<BaseStateModel>({
    name: BASESTATE_TOKEN,
    defaults: DEFAULT_STATE,
    children: [], //ce bo kdaj prov prislo
})
@Injectable()
export class BaseState {
    // private selectedContractor: Contractor = this.store.selectSnapshot(BaseState.activeContractor);

    // @TODO: Ignored with eslint-interactive on 2023-10-13
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    // @Selector([BASESTATE_TOKEN])
    // public static getToken(state: BaseStateModel): string | undefined {
    //     return state.token?.accessToken;
    // }

    // @Selector([BASESTATE_TOKEN])
    // public static getRefreshToken(state: BaseStateModel): string | undefined {
    //     return state.token?.refreshToken;
    // }

    // // @Selector([BASESTATE_TOKEN])
    // // public static getBS(state: BaseStateModel): BusinessSubject {
    // //     return state?.user?.businessSubjects[0] || { id: -1 };
    // // }

    // @Selector([BASESTATE_TOKEN])
    // public static getBSId(state: BaseStateModel): number {
    //     return state?.user?.businessSubjects[0].id || -1;
    // }

    // @Selector([BASESTATE_TOKEN])
    // public static getUser(state: BaseStateModel): User | undefined {
    //     return state?.user;
    // }

    // @Selector([BASESTATE_TOKEN])
    // public static getBsGuiSettings(state: BaseStateModel): BsGuiSettings | undefined {
    //     return state?.bsGuiSettings;
    // }

    // @Selector([BASESTATE_TOKEN])
    // public static getContractors(state: BaseStateModel): Contractor[] {
    //     return state.contractors;
    // }

    // @Selector([BASESTATE_TOKEN])
    // public static getSelectedContractor(state: BaseStateModel): Contractor {
    //     return <Contractor>state.selectedContractor;
    // }

    // @Selector([BASESTATE_TOKEN])
    // public static getSelectedContractorId(state: BaseStateModel): number {
    //     return <number>state.selectedContractor?.id;
    // }

    // @Selector([BASESTATE_TOKEN])
    // public static GetActiveModules(state: BaseStateModel): Module[] {
    //     return state.appModules;
    // }
    // @Selector([BASESTATE_TOKEN])
    // public static getLoginInfo(state: BaseStateModel): LoginInfo {
    //     return state.loginInfo;
    // }

    @Action(BaseStateActions.Common.SetTokenData)
    public SetActiveContractor(ctx: StateContext<BaseStateModel>, { tokenData }: BaseStateActions.Common.SetTokenData) {
        ctx.patchState({
            token: tokenData,
        });
    }

    @Action(BaseStateActions.Common.SetBsGuiSettings)
    public SetBsGuiSettings(ctx: StateContext<BaseStateModel>, { settings }: BaseStateActions.Common.SetBsGuiSettings) {
        // debugger;
        if (settings) {
            ctx.patchState({
                bsGuiSettings: settings,
                appModules: settings?.settings?.active.map(el => {
                    return {
                        name: el,
                    };
                }),
            });
        }
    }

    @Action(BaseStateActions.Common.SetUser)
    public SetUser(ctx: StateContext<BaseStateModel>, { user }: BaseStateActions.Common.SetUser) {
        ctx.patchState({
            user: user,
        });
    }

    @Action(BaseStateActions.Common.ClearBaseState)
    // @TODO: Ignored with eslint-interactive on 2023-10-13
    // eslint-disable-next-line no-empty-pattern
    public ClearState(ctx: StateContext<BaseStateModel>, {}: BaseStateActions.Common.ClearBaseState) {
        ctx.patchState(DEFAULT_STATE);
    }

    @Action(BaseStateActions.Common.SetContractors)
    public SetContractors(ctx: StateContext<BaseStateModel>, { contractors }: BaseStateActions.Common.SetContractors) {
        ctx.patchState({
            contractors: contractors,
        });
    }

    @Action(BaseStateActions.Contractor.SetSelectedContractor)
    public SetSelectedContractor(ctx: StateContext<BaseStateModel>, { contractor }: BaseStateActions.Contractor.SetSelectedContractor) {
        ctx.patchState({
            selectedContractor: contractor,
        });
    }

    @Action(BaseStateActions.Contractor.SetContractorSettings)
    public SetContractorSettings(ctx: StateContext<BaseStateModel>, { settings }: BaseStateActions.Contractor.SetContractorSettings) {
        ctx.patchState({
            contractorGuiSettings: settings,
        });
    }

    @Action(BaseStateActions.Contractor.SetContractorsModules)
    @Action(BaseStateActions.Bs.SetBsModules)
    public SetContractorsModules(ctx: StateContext<BaseStateModel>, { modules }: BaseStateActions.Contractor.SetContractorsModules) {
        ctx.patchState({
            appModules: modules,
        });
    }

    @Action(BaseStateActions.Common.SetLanguage)
    public SetLanguage(ctx: StateContext<BaseStateModel>, { language }: BaseStateActions.Common.SetLanguage) {
        ctx.patchState({
            language: language,
        });
    }
    @Action(BaseStateActions.Common.SetActiveBS)
    public SetActiveBS(ctx: StateContext<BaseStateModel>, { activeBs }: BaseStateActions.Common.SetActiveBS): void {
        ctx.patchState({
            activeBS: activeBs,
        });
    }
    @Action(BaseStateActions.Common.AddFiredAction)
    public AddFiredAction(ctx: StateContext<BaseStateModel>, { firedAction }: BaseStateActions.Common.AddFiredAction): void {
        const allFiredActions = [firedAction, ...(ctx.getState().firedActions || [])].slice(0, 99);
        ctx.patchState({
            firedActions: allFiredActions,
        });
    }
}
