export enum AppModulesType {
    SEND_DOCUMENTATION = 'SEND_DOCUMENTATION',
    OIM_TRACKING = 'OIM_TRACKING',
    CASE = 'CASE',
    CASE_STATISTICS = 'CASE_STATISTICS',
    CASE_SEPARATED_INBOX = 'CASE_SEPARATED_INBOX',
    ONLINE_RESERVATIONS = 'ONLINE_RESERVATIONS',
    PREVENTIVNI_COMPANIES = 'PREVENTIVNI_COMPANIES',
    MCIP_INTEGRATION = 'MCIP_INTEGRATION',
    SURVEYS_STATISTICS = 'SURVEYS_STATISTICS',
}
