import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PipesModule } from './../../pipes/pipes.module';
import { MedaViewComponent } from './meda-view.component';

@NgModule({
    imports: [PipesModule, CommonModule, NgbModule, PdfViewerModule],
    declarations: [MedaViewComponent],
    providers: [],
    exports: [],
})
export class MedaViewModule {}
