import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseStateActions } from 'src/app/_common/state/base/base.actions';
import { Captcha } from '../../../_common/model/captcha.model';
import { environment } from './../../../../environments/environment';
import { User } from './../../../_common/model/user';
import { ApiUrlService } from './../../../_common/services/api-url.service';
import { HttpClientService } from './../../../_common/services/http-client.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(
        private httpClient: HttpClientService,
        private helper: ApiUrlService,
        private store: Store,
    ) {}

    public login(username: string, password: string): Observable<boolean> {
        const options = {
            headers: new HttpHeaders({
                authorization: 'Basic ' + environment.AUTHPASS,
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
        };
        return this.httpClient
            .post(
                this.helper.getAuthUrl(),
                'username=' + encodeURIComponent(username?.trim()) + '&password=' + encodeURIComponent(password) + '&grant_type=password',
                options,
            )
            .pipe(
                map(token => {
                    this.store.dispatch(
                        new BaseStateActions.Common.SetTokenData({
                            accessToken: token.access_token,
                            refreshToken: token.refresh_token,
                            sdata: token.sdata,
                        }),
                    );

                    return token;
                }),
                catchError((e: any) => {
                    console.error($localize`Napaka pri: AuthenticationService`);
                    return throwError(e);
                }),
            );
    }

    public getUserData(): Observable<User> {
        return this.httpClient.get(this.helper.getAssistanceUrl(2, 'userinfo/'));
        // .pipe(map((el) => new User().deserialize(el)));
    }

    public getContractorUserData(): Observable<User> {
        return this.httpClient.get(this.helper.getEambulantaApi(2, 'userinfo/'));
        // .pipe(map((el) => new User().deserialize(el)));
    }

    public getCaptcha(): Observable<Captcha> {
        return this.httpClient.get(this.helper.getCaptchaUrl());
    }

    public solveUserCaptcha(username: string, guid: string, x: number, y: number): Observable<boolean> {
        return this.httpClient.get(this.helper.getCaptchaUrl() + `/users/${username}/validate/${guid}/${x}/${y}`);
    }
}
