<div class="mf-lay-sub-menu">
    <div class="d-flex align-items-center pl-2">
        <!-- @TODO: Ignored manually on 2023-10-13 -->
        <!-- eslint-disable @angular-eslint/template/use-track-by-function -->
        <a
            *ngFor="let item of sumbMenu"
            [routerLink]="item.link"
            [routerLinkActiveOptions]="{ exact: true }"
            routerLinkActive="mf-lay-sub-menu_item--active"
            class="mf-lay-sub-menu_item text-uppercase pt-2 mx-2"
        >
            {{ item.title }}
        </a>
        <!-- eslint-enable -->
    </div>
</div>
