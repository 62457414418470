import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { AppModulesType } from './../enums/appModulesType.enum';
import { MenuGroup } from './../model/menu-group';
import { ActiveModulesService } from './active-modules.service';
import { RulesService } from './rules.service';

@Injectable({
    providedIn: 'root',
})
export class MenuService {
    public refreshMenu$ = new Subject<void>();
    private mainMenu: MenuGroup[] = [
        {
            groupTitle: $localize`Razpoložljivost`,
            menu: [
                {
                    title: $localize`Vidnost terminov`,
                    icon: 'las pl-1 la-calendar',
                    link: '/availability',
                    module: AppModulesType.MCIP_INTEGRATION,
                },
            ],
        },
        {
            groupTitle: $localize`Samoplačniške storitve`,
            menu: [
                {
                    title: $localize`Spletna naročila`,
                    icon: 'las pl-1 la-umbrella',
                    link: '/case',
                    module: AppModulesType.CASE,
                },
                {
                    title: $localize`Poročilo`,
                    icon: 'las pl-1 la-shopping-cart',
                    link: '/online-reservations',
                    module: AppModulesType.ONLINE_RESERVATIONS,
                },
                {
                    title: $localize`Ankete Generali`,
                    icon: 'las pl-1 la-poll',
                    link: '/survey-statistics',
                    module: AppModulesType.SURVEYS_STATISTICS,
                    rule: 'self-pay-generali-stat-visible',
                },
            ],
        },

        {
            groupTitle: $localize`Komunikacija s pacienti`,
            menu: [
                {
                    title: $localize`Navodila pacientom`,
                    icon: 'las pl-1 la-paper-plane',
                    link: '/send-documentation',
                    module: AppModulesType.SEND_DOCUMENTATION,
                },
            ],
        },
        {
            groupTitle: $localize`Komunikacija z delodajalci`,
            menu: [
                {
                    title: $localize`Delodajalci MDPŠ`,
                    icon: 'las pl-1 la-industry',
                    link: '/preventivni-companies',
                    module: AppModulesType.PREVENTIVNI_COMPANIES,
                },
            ],
        },
        // {
        //     groupTitle: 'Nastavitve',
        //     menu: [{ title: 'Test', icon: 'las  mf-i-bubble-2', link: '/settings' }],
        // },

        {
            groupTitle: $localize`Uporabnik`,
            menu: [
                // { title: 'Nastavitve', icon: 'las  mf-i-nut', link: '/settings/user' },
                { title: $localize`Moj račun`, icon: 'pl-1  las  la-user', link: '/settings/user-profile' },
                { title: $localize`Odjava`, icon: 'pl-1  las  la-sign-out-alt', link: '/public/logout' },
            ],
        },
        {
            groupTitle: $localize`OIM`,
            menu: [
                {
                    title: $localize`Kanban`,
                    // icon: 'las pl-1 la-grip-horizontal',
                    // icon: 'las pl-1 la-columns',
                    icon: 'lab pl-1 la-trello',
                    link: '/oim/kanban',
                    module: AppModulesType.OIM_TRACKING,
                },
                {
                    title: $localize`Seznam naročil`,
                    icon: 'las pl-1 la-th-list',
                    link: '/oim/order-list',
                    module: AppModulesType.OIM_TRACKING,
                },
            ],
        },
    ];

    constructor(
        private am: ActiveModulesService,
        private permissionsService: RulesService,
    ) {}

    public getMainMenu(): MenuGroup[] {
        // debugger;
        return this.validateMenu(_.cloneDeep(this.mainMenu));
        // return this.validateMenu(JSON.parse(JSON.stringify(this.mainMenu)));
    }

    private validateMenu(menuGroup: MenuGroup[]): MenuGroup[] {
        const allRules = this.permissionsService.getAllRules();
        const res = menuGroup.filter(menuG => {
            menuG.menu = menuG.menu.filter(item => {
                if (item.rule && !allRules[item.rule]) {
                    return false;
                }
                if (item.module != undefined) {
                    return this.am.isAM(item.module);
                }
                return true;
            });
            return menuG?.menu?.length > 0;
        });
        return res;
    }
}
