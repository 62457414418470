<div class="mf-men-header position-relative">
    <i (click)="minMenu()" class="mf-min-icon las la-2x" [ngClass]="isMin ? 'mf-i-chevron2-right' : 'mf-i-chevron2-left'"></i>

    <div class="mf-men-header_wrapper">
        <div class="d-flex flex-column align-items-center p-3">
            <div class="mf-men-header_logo pt-2 pb-4">
                <img src="assets/svg/network_logotyp-solo-white.svg" alt="Network" class="mx-auto" i18n-alt />
            </div>
            <div class="mf-men-header_partner">
                <h5 class="font-weight-bold text-center text-uppercase">{{ bs?.name }}</h5>
            </div>
            <!-- 
            <div ngbDropdown container="body" *ngIf="user?.businessSubjects?.length > 1">
                <button class="btn btn-secondary text-wrap text-uppercase" id="dropdownBasic1" ngbDropdownToggle>
                    <h5 class="d-inline-block mb-0">
                        {{ bs?.name }}
                    </h5>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <h6 class="dropdown-header">Zamenjajte lokacijo</h6>
                    <button ngbDropdownItem *ngFor="let bs of user?.businessSubjects">{{ bs?.name }}</button>
                </div>
            </div> -->
            <div class="mf-men-header_user">
                <div class="mf-men-header_user-max text-center">
                    <i class="las mf-i-user la-5x"></i>
                    <div class="font-weight-bold text-uppercase">{{ user?.name }} {{ user?.surname }}</div>
                    <!-- <div class="text-break">Podporni steber oddelka</div> -->
                </div>
                <div class="mf-men-header_user-min">
                    <div class="mf-men-header_user-min_circle">{{ user?.name?.slice(0, 1) }}{{ user?.surname?.slice(0, 1) }}</div>
                </div>
            </div>
        </div>
    </div>
</div>
