import { Component, ComponentFactoryResolver, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from './../../../../environments/environment';
import { ComPopupHostDirective } from './com-popup-host.directive';

@Component({
    selector: 'com-base-popup',
    templateUrl: './com-base-popup.component.html',
    styleUrls: ['./com-base-popup.component.scss'],
})
export class ComBasePopupComponent implements OnInit {
    @Input()
    public component: any;
    @Input()
    public settings: any;

    public env: any = environment;

    @ViewChild(ComPopupHostDirective, { static: true }) comHost?: ComPopupHostDirective;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private modalService: NgbModal,
        public activeModal: NgbActiveModal,
    ) {}

    ngOnInit() {
        this.loadComponent();
    }

    loadComponent() {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.component);
        const viewContainerRef = this.comHost?.viewContainerRef;
        viewContainerRef?.clear();
        const componentRef = viewContainerRef?.createComponent<any>(componentFactory);
        componentRef?.instance.onClosePopup.subscribe(() => {
            this.activeModal.close('Close click');
        });
    }
}
