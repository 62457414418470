import { FiredAction } from '../../../_modules/case-statistics/cas-separated/model/firedAction.model';
import { Contractor } from '../../model/contractor.model';
import { TokenData } from './../../../_modules/public/_model/token-data';
import { BsGuiSettings } from './../../model/bs-gui-settings';
import { BusinessSubject } from './../../model/business-subject';
import { Module } from './../../model/module.model';
import { User } from './../../model/user';
// @TODO: Ignored with eslint-interactive on 2023-10-13
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace BaseStateActions {
    // @TODO: Ignored with eslint-interactive on 2023-10-13
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace Common {
        export class SetTokenData {
            public static readonly type = '[common] SetTokenData';
            constructor(public readonly tokenData: TokenData) {}
        }

        export class SetUser {
            public static readonly type = '[common] SetUser';
            constructor(public readonly user: User | undefined) {}
        }
        /**
         * @deprecated Moduli so se prestavili v lastni klic
         */
        export class SetBsGuiSettings {
            public static readonly type = '[common] SetBsGuiSettings';
            constructor(public readonly settings: BsGuiSettings) {}
        }

        export class ClearBaseState {
            public static readonly type = '[common] ClearBaseState';
            // constructor(public readonly tokenData: TokenData) {}
        }

        export class SetContractors {
            public static readonly type = '[common] SetContractors';
            constructor(public readonly contractors: Contractor[]) {}
        }

        export class SetLanguage {
            public static readonly type = '[common] SetLanguage';
            constructor(public readonly language: string) {}
        }
        export class SetActiveBS {
            public static readonly type = '[common] SetActiveBS';
            constructor(public readonly activeBs: BusinessSubject) {}
        }
        export class AddFiredAction {
            public static readonly type = '[common] AddFiredAction';
            constructor(public readonly firedAction: FiredAction) {}
        }
    }
    // @TODO: Ignored with eslint-interactive on 2023-10-13
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace Contractor {
        export class SetSelectedContractor {
            public static readonly type = '[contractor] SetSelectedContractor';
            constructor(public readonly contractor: Contractor) {}
        }

        export class SetContractorSettings {
            public static readonly type = '[contractor] SetContractorSettings';
            constructor(public readonly settings: BsGuiSettings) {}
        }

        export class SetContractorsModules {
            public static readonly type = '[contractor] SetContractorsModules';
            constructor(public readonly modules: Module[]) {}
        }
    }

    // @TODO: Ignored with eslint-interactive on 2023-10-13
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace Bs {
        export class SetBsModules {
            public static readonly type = '[Bs] SetBsModules';
            constructor(public readonly modules: Module[]) {}
        }
    }
}
