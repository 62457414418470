import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { LanguageService } from './_common/services/language.service';
import { RulesService } from './_common/services/rules.service';
import { BaseSelectors } from './_common/state/base/base.selectors';
declare global {
    interface Window {
        dataLayer: any[];
    }
}
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    constructor(
        private rules: RulesService,
        private store: Store,
        public router: Router,
        private languageService: LanguageService,
    ) {
        this.router.events?.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (!event.urlAfterRedirects?.includes('login')) {
                    const bsID = this.store.selectSnapshot(BaseSelectors.getBSId);
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        bsId: bsID,
                    });
                }
            } else if (event instanceof NavigationStart) {
                this.languageService.validateOrRedirectRoute();
            }
        });
    }
    title = 'network';

    ngOnInit() {
        this.rules.setRules();
        this.languageService.initLocale();
    }
}
