import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { BaseStateActions } from '../../_common/state/base/base.actions';

@Component({
    // @TODO: Ignored with eslint-interactive on 2023-10-13
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'index',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.scss'],
})
export class IndexComponent implements OnInit {
    constructor(
        private store: Store,
        private router: Router,
    ) {}

    // @TODO: Ignored with eslint-interactive on 2023-10-13
    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
    ngOnInit() {}

    logout() {
        this.store.dispatch(BaseStateActions.Common.ClearBaseState).subscribe(() => this.router.navigate(['/']));
        this.router.navigate(['/public/login']);
    }
}
