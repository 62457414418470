import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MenuGroup } from './../../../../_common/model/menu-group';
import { MenuService } from './../../../../_common/services/menu.service';

@Component({
    selector: 'lay-men-menu',
    templateUrl: './lay-men-menu.component.html',
    styleUrls: ['./lay-men-menu.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LayMenMenuComponent implements OnInit, OnDestroy {
    @Input()
    public isMin = false;

    public menuGroup: MenuGroup[] = [];
    private onDestroy$ = new Subject<void>();
    constructor(private menu: MenuService) {}

    ngOnInit(): void {
        this.menuGroup = this.menu.getMainMenu();
        this.menu?.refreshMenu$.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
            this.menuGroup = this.menu.getMainMenu();
        });
    }
    ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}
