import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from './../../../../../environments/environment';

@Component({
    selector: 'lay-men-footer',
    templateUrl: './lay-men-footer.component.html',
    styleUrls: ['./lay-men-footer.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LayMenFooterComponent implements OnInit {
    public en = environment;
    // @TODO: Ignored with eslint-interactive on 2023-10-13
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    // @TODO: Ignored with eslint-interactive on 2023-10-13
    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
    ngOnInit() {}
}
