<!-- @TODO: Ignored manually on 2023-10-13 -->
<!-- eslint-disable @angular-eslint/template/use-track-by-function -->
<div *ngFor="let group of menuGroup" class="mf-menu-group pb-2">
    <!-- eslint-enable -->
    <div class="text-uppercase mf-menu-group_title">
        <span class="mf-menu-group_title_text pl-2 font-weight-bold">
            {{ group.groupTitle }}
        </span>
    </div>

    <!-- @TODO: Ignored manually on 2023-10-13 -->
    <!-- eslint-disable @angular-eslint/template/use-track-by-function, -->
    <div *ngFor="let menu of group.menu" class="mf-menu">
        <!-- eslint-enable -->
        <!-- @TODO: Ignored manually on 2023-10-13 -->
        <!-- eslint-disable @angular-eslint/template/i18n -->
        <a
            [routerLink]="menu.link"
            [ngbTooltip]="menu.title"
            [disableTooltip]="!isMin"
            [routerLinkActiveOptions]="{ exact: false }"
            tooltipClass="mf-tooltip"
            placement="right"
            container="body"
            routerLinkActive="mf-menu_item--active"
            class="mf-menu_item"
        >
            <i class="pr-1" [ngClass]="menu.icon"></i>
            <span class="mf-menu_item_text">{{ menu.title }}</span>
        </a>
        <!-- eslint-enable -->
    </div>
</div>
