import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AppModulesType } from './../enums/appModulesType.enum';
import { Module } from './../model/module.model';
import { BaseSelectors } from './../state/base/base.selectors';

@Injectable({
    providedIn: 'root',
})
export class ActiveModulesService {
    constructor(private store: Store) {}

    public getAM(): string[] | undefined {
        return this.store.selectSnapshot(BaseSelectors.getBsGuiSettings)?.settings?.active;
    }

    // public isAM(module: AppModulesType): boolean | undefined {
    //     return this.store.selectSnapshot(BaseState.getBsGuiSettings)?.settings?.active?.includes(module);
    // }

    public isAM(module: AppModulesType): boolean {
        // console.log('AM ', this.store.selectSnapshot(BaseState.GetActiveModules));
        return this.store
            .selectSnapshot(BaseSelectors.GetActiveModules)
            ?.map((el: Module) => el.name)
            .includes(module);
    }
}
