import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { ActiveModulesService } from './../services/active-modules.service';
@Injectable()
export class ActiveModuleGuard implements CanActivate {
    constructor(
        private router: Router,
        private activeModules: ActiveModulesService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        if (_.has(route, 'data.activeModule.only') && this.activeModules.isAM(route.data.activeModule.only)) {
            return true;
        }
        this.router.navigate(['/' + _.get(route, 'data.activeModule.redirectTo', 'index')]);
        return false;
    }

    // canActivateChild(
    //     route: ActivatedRouteSnapshot,
    //     state: RouterStateSnapshot,
    // ): Observable<boolean> | Promise<boolean> | boolean {
    //     debugger;
    //     return true;
    // }
}
