import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';
import { BaseSelectors } from './../state/base/base.selectors';

@Injectable({
    providedIn: 'root',
})
export class RulesService {
    constructor(
        private permissionsService: NgxPermissionsService,
        private store: Store,
    ) {}

    private allRules: any = {
        ROLE_NET_USER: [],
        ROLE_NET_OWNER: ['send_documentation__menu_send-visible', 'self-pay-generali-stat-visible'],
    };

    public setRules(): void {
        const roles = this.store.selectSnapshot(BaseSelectors.getUser)?.roles;
        if (roles === null) {
            return;
        }
        if (roles) {
            const userPermissions: string[] = [];
            roles.map(el => {
                if (this.allRules[el]) {
                    userPermissions.push(...this.allRules[el]);
                }
            });
            this.permissionsService.loadPermissions(userPermissions);
        }
    }

    public getAllRules(): NgxPermissionsObject {
        return this.permissionsService.getPermissions();
    }
}
