/* eslint-disable @typescript-eslint/no-explicit-any */
import { environment as env } from '../../../environments/environment';

export class EnvironmentHelper {
    public static initEnvironment(): void {
        const environment = env as any;
        const configJson = (<any>window).environment?.envConfig as any;
        EnvironmentHelper.overwriteEnvironment(environment, configJson);
    }

    private static overwriteEnvironment(envJson: any, jsonObject: any): any {
        for (const key in jsonObject) {
            if (typeof jsonObject[key] === 'object' && jsonObject[key] !== null && !Array.isArray(jsonObject[key])) {
                this.overwriteEnvironment(envJson[key], jsonObject[key]);
                // eslint-disable-next-line no-prototype-builtins
            } else if (envJson.hasOwnProperty(key)) {
                envJson[key] = jsonObject[key];
            }
        }
        return envJson;
    }
}
