import { LanguageItem } from '../app/_common/model/language-switcher/language-item.model';
import { EnvironmentModel } from './environment.model';

export const environment: EnvironmentModel = {
    production: true,
    AUTHPASS: btoa('network:test'),
    // @TODO: Ignored with eslint-interactive on 2023-10-13
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    VERSION: require('../../package.json').version,
    reconnectInterval: 2000,
    INTERCOM_KEY: 'ky5rkdlu',
    RELEASE_STAGE: 'production',
    contentHubToken: '9885c64f552b93471e103545cd2b3444ce452a33cfb716c18a2aa14c6012c952274c1833525e4c4404310d234fc2c7c89027deafb8f1265e5fe40cc25a822be6dbe47670647a12232935adcd0e449be8cab7ba6f24ea9ca393dd289ca47130e79278f0098c8f13564b97b406003b79eebd738c006680bef0b6e883859e0d890a',
    contentHub: {
        protocol: 'https://',
        rootUrl: 'content.medifit.si',
        port: '',
        url: '',
    },
    auth: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/oauth/token',
    },
    captcha: {
        url: '/captcha',
    },
    root: {
        v1: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si',
            port: '',
            url: '/booking',
        },
        v2: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si',
            port: '',
            url: '/booking',
        },
        v3: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si',
            port: '',
            url: '/booking',
        },
    },

    assistance: {
        v1: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '/assistance/api',
        },
        v2: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '/v2/assistance/api',
        },
        v3: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '/asv2/assistance/api',
        },
    },
    assistanceRoot: {
        v3: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '/asv2',
        },
    },

    network: {
        v1: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '/network',
        },
    },
    ea: {
        v1: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '/erp/api',
        },
        v2: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '/eav2/erp/api',
        },
    },
    admin: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/v2/admin/api/',
    },
    languageSettings: {
        languages: [
            {
                value: 'en',
                imagePath: 'assets/svg/flags/en.svg',
            } as LanguageItem,
            {
                value: 'sl',
                imagePath: 'assets/svg/flags/sl.svg',
            } as LanguageItem,
            // {
            //     value: 'sr',
            //     imagePath: 'assets/svg/flags/sr.svg',
            // } as LanguageItem,
        ],
    }
};
