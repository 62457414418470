import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppModulesType } from './_common/enums/appModulesType.enum';
import { ActiveModuleGuard } from './_common/guards/active-module.guard';
import { AuthGuard } from './_common/guards/auth.guard';
import { IndexComponent } from './_modules/index/index.component';
import { ThmIndexMenuComponent } from './_theme/thm-index-menu/thm-index-menu.component';

const routes: Routes = [
    {
        path: 'public',
        loadChildren: () => import('./_modules/public/public.module').then(m => m.PublicModule),
    },
    {
        path: '',
        component: ThmIndexMenuComponent,
        canActivateChild: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('./_modules/index/index.module').then(m => m.IndexModule),
                component: IndexComponent,
            },

            {
                path: 'send-documentation',
                canActivate: [ActiveModuleGuard],
                data: {
                    // permissions: {
                    //     only: 'menu_main_inbox_device__Visible',
                    //     redirectTo: 'inbox',
                    // },
                    activeModule: {
                        only: AppModulesType.SEND_DOCUMENTATION,
                        redirectTo: '',
                    },
                },
                loadChildren: () => import('./_modules/send-documentation/send-documentation.module').then(m => m.SendDocumentationModule),
            },

            {
                path: 'case',
                canActivate: [ActiveModuleGuard],
                data: {
                    // permissions: {
                    //     only: 'menu_main_inbox_device__Visible',
                    //     redirectTo: 'inbox',
                    // },
                    activeModule: {
                        only: AppModulesType.CASE,
                        redirectTo: '',
                    },
                },
                loadChildren: () => import('./_modules/case-statistics/case-statistics.module').then(m => m.CaseStatisticsModule),
            },
            {
                path: 'availability',
                canActivate: [ActiveModuleGuard],
                data: {
                    activeModule: {
                        only: AppModulesType.MCIP_INTEGRATION,
                        redirectTo: '',
                    },
                },
                loadChildren: () => import('./_modules/availability/availability.module').then(m => m.AvailabilityModule),
            },
            {
                path: 'online-reservations',
                canActivate: [ActiveModuleGuard],
                data: {
                    // permissions: {
                    //     only: 'menu_main_inbox_device__Visible',
                    //     redirectTo: 'inbox',
                    // },
                    activeModule: {
                        only: AppModulesType.ONLINE_RESERVATIONS,
                        redirectTo: '',
                    },
                },
                loadChildren: () => import('./_modules/online-reservations/online-reservations.module').then(m => m.OnlineReservationsModule),
            },

            {
                path: 'preventivni-companies',
                canActivate: [ActiveModuleGuard],
                data: {
                    // permissions: {
                    //     only: 'menu_main_inbox_device__Visible',
                    //     redirectTo: 'inbox',
                    // },
                    activeModule: {
                        only: AppModulesType.PREVENTIVNI_COMPANIES,
                        redirectTo: '',
                    },
                },
                loadChildren: () => import('./_modules/preventivni-companies/preventivni-companies.module').then(m => m.PreventivniCompaniesModule),
            },

            {
                path: 'oim',
                loadChildren: () => import('./_modules/oim/oim.module').then(m => m.OimModule),
            },

            {
                path: 'settings',
                loadChildren: () => import('./_modules/settings/settings.module').then(m => m.SettingsModule),
            },
            {
                path: 'survey-statistics',
                canActivate: [ActiveModuleGuard],
                data: {
                    activeModule: {
                        only: AppModulesType.SURVEYS_STATISTICS,
                        redirectTo: '',
                    },
                },
                loadChildren: () => import('./_modules/survey-statistics/survey-statistics.module').then(m => m.SurveyStatisticsModule),
            },
        ],
    },
    {
        path: '',
        children: [
            {
                path: '',
                loadChildren: () => import('./_modules/layout/layout.module').then(m => m.LayoutModule),
                component: IndexComponent,
            },
        ],
    },
    { path: '**', redirectTo: '/' },
    // { path: '', redirectTo: 'public/login', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
