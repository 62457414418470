import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import * as moment from 'moment';
import { Intercom } from 'ng-intercom';
import { environment } from 'src/environments/environment';
import { MenuItem } from './../../_common/model/menu-item';
import { RulesService } from './../../_common/services/rules.service';
import { BaseSelectors } from './../../_common/state/base/base.selectors';

@Component({
    selector: 'thm-index-menu',
    templateUrl: './thm-index-menu.component.html',
    styleUrls: ['./thm-index-menu.component.scss'],
})
export class ThmIndexMenuComponent implements OnInit {
    public min = false;
    public submenu: MenuItem[] = [];
    public userData = this.store.selectSnapshot(BaseSelectors.getUser);
    constructor(
        private permissionsService: RulesService,
        private intercom: Intercom,
        private store: Store,
    ) {}

    ngOnInit(): void {
        if (environment.RELEASE_STAGE != 'test') {
            this.intercom.boot({
                app_id: environment.INTERCOM_KEY,
                name: this.userData?.name, // Full name
                email: this.userData?.email, // Email address
                created_at: moment().unix(), // Signup date as a Unix timestamp
                subcontractorId: this.userData?.subcontractorId,
                phone: this.userData?.phone,
                widget: {
                    activator: '#intercom',
                },
            });
        }
    }

    activate(event: { submenu: MenuItem[] }): void {
        this.submenu = this.validateSubMenu(event?.submenu);
    }

    private validateSubMenu(subMenu: MenuItem[]): MenuItem[] {
        const allPermissions = this.permissionsService.getAllRules();
        if (!subMenu) {
            return [];
        }
        return subMenu?.filter(menu => {
            if (!menu.rule) {
                return true;
            }
            if (allPermissions[menu.rule]) {
                return true;
            }
            return false;
        });
    }
}
