import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import * as moment from 'moment';
import { environment } from './../../../environments/environment';
import { ComBasePopupComponent } from './../component/com-base-popup/com-base-popup.component';
import { ComLoadingComponent } from './../component/com-loading/com-loading.component';
import { BusinessSubject } from './../model/business-subject';

@Injectable({
    providedIn: 'root',
})
export class HelperService {
    private overlayRef: OverlayRef | null = null;
    public ngxDataTableTransletedMessages: any = {
        emptyMessage: $localize`Ni podatkov`,
        totalMessage: $localize`skupaj`,
    };
    constructor(
        private modalService: NgbModal,
        private overlay: Overlay,
    ) {}

    public isRtcSupported(): boolean {
        const agent = window?.navigator?.userAgent?.toLowerCase();
        return agent.indexOf('trident') < 0;
    }
    public openMFPopover(component: any, settings?: any): any {
        const modalNewTerm = this.modalService.open(ComBasePopupComponent, {
            windowClass: 'mf-responsive-popup mf-brand-popup',
            backdrop: 'static',
        });

        modalNewTerm.componentInstance.component = component;
        modalNewTerm.componentInstance.settings = settings;

        return modalNewTerm;
    }

    public showLoading(message: string = $localize`Prosimo počakajte...`) {
        // Returns an OverlayRef (which is a PortalHost)
        // debugger;
        if (!this.overlayRef) {
            this.overlayRef = this.overlay.create();
        }
        // Create ComponentPortal that can be attached to a PortalHost
        const spinnerOverlayPortal = new ComponentPortal(ComLoadingComponent);
        const component = this.overlayRef.attach(spinnerOverlayPortal); // Attach ComponentPortal to PortalHost
        // component.instance.message = message;
    }

    public hideLoading() {
        if (this.overlayRef) {
            this.overlayRef.detach();
        }
    }

    public fromMomentToNgb(date: moment.Moment): NgbDateStruct | null {
        if (!date) {
            return null;
        }
        return { year: date.year(), month: date.month(), day: date.day() };
    }

    public toMomentFromNgb(date: NgbDateStruct): moment.Moment | null {
        if (!date) {
            return null;
        }
        return moment(date.year + '-' + date.month + '-' + date.day, 'YYYY-MM-DD');
    }

    public getVersion(): string {
        return environment.VERSION;
    }

    public parseMFError(error: any): any {
        if (error) {
            return _.get(error, 'errors', []).map((er: { msg: string }) => {
                return er.msg;
            });
        }
        return '';
    }

    public checkStructError(error: any, code: string): boolean {
        error = JSON.parse(error);
        const err = _.get(error, 'errors[0]', undefined);
        if (err) {
            if (err.code === code || err.msg === code) {
                return true;
            }
        }
        return false;
    }
    getTokenDirectFromStorage() {
        return JSON.parse(localStorage.getItem('@@STATE') || '""')?.basestate?.token?.accessToken;
    }

    public getBusiness(bs: any): BusinessSubject {
        // prejmeš Business objekt
        if (bs.bsid !== undefined) {
            return {
                id: bs.bsid,
                name: _.get(bs, 'name', 'Interno'),
                iconPath: _.get(bs, 'iconPath', 'assets/svg/network_znak-pink.svg'),
            };
        }
        // prejmeš samo bsId ali drugače definiran Business
        let tmp = bs.id;
        if (tmp === undefined) {
            tmp = bs;
        }
        switch (tmp) {
            case 1:
            case 11:
                return {
                    id: bs.id,
                    name: 'Generali',
                    iconPath: 'assets/svg/bs/generali.svg',
                };
            case 2:
                return {
                    id: bs.id,
                    name: 'Triglav',
                    iconPath: 'assets/svg/bs/triglav.svg',
                };
            case 3:
                return {
                    id: bs.id,
                    name: 'Merkur',
                    iconPath: 'assets/svg/bs/merkur.svg',
                };
            case 4:
                return {
                    id: bs.id,
                    name: 'M zavarovalnica',
                    iconPath: 'assets/svg/bs/medifit_icon.svg',
                };
            case 6:
                return {
                    id: bs.id,
                    name: 'Doktor 24',
                    iconPath: 'assets/svg/bs/logo_dr24.png',
                };
            case 7:
                return {
                    id: bs.id,
                    name: 'Vzajemna',
                    iconPath: 'assets/svg/bs/vzajemna.svg',
                };
            case 8:
                return {
                    id: bs.id,
                    name: 'Medifit',
                    iconPath: 'assets/svg/bs/medifit_icon.svg',
                };
            case 9:
                return {
                    id: bs.id,
                    name: 'Sava',
                    iconPath: 'assets/svg/bs/sava_icon.svg',
                };
            case -111:
                return {
                    id: bs.id,
                    name: !_.isNil(bs.name) ? `${bs.name}` : $localize`Zunanja rezervacija`,
                    iconPath: 'assets/demo/demo5/media/img/logo/logo1.svg',
                };
            default:
                return {
                    id: bs.id,
                    name: !_.isNil(bs.name) ? `${bs.name}` : '',
                    iconPath: '../../../assets/demo/demo5/media/img/logo/logo1.svg',
                };
        }
    }

    // filter za več inputov
    public filterArrayByCustomFilter(data: any[], filters: any[]) {
        filters = filters.filter(fil => fil.value !== '');

        data = data.filter(element => {
            return filters.every(fil => {
                if (fil.subtypes === undefined) {
                    if (_.isNil(element[fil.type])) {
                        return null;
                    }
                    return element[fil.type].toString().toLowerCase().indexOf(fil.value) !== -1 ? true : false;
                } else {
                    if (_.isNil(element[fil.type])) {
                        return false;
                    }
                    const filterString = JSON.stringify(
                        fil.subtypes.map((subfil: any) => {
                            if (fil.caseElementType === 'object') {
                                if (_.isNil(element[fil.type][subfil])) {
                                    return null;
                                }
                                return element[fil.type][subfil].toString().toLowerCase();
                            } else {
                                return element[fil.type].map((el: any) => {
                                    if (el[subfil]) {
                                        return el[subfil].toString().toLowerCase();
                                    }
                                    return '';
                                });
                            }
                        }),
                    );
                    const inputString: string[] = fil.value.toString().toLowerCase().split(' ');
                    return inputString.every(inputElement => {
                        return filterString.indexOf(inputElement) !== -1 ? true : false;
                    });
                }
            });
        });

        return data;
    }

    public replaceSlahsForDolar(data: string): string {
        if (!data) {
            return data;
        }
        return data.split('/').join('$$$');
    }

    uuid(length: number = 8): string {
        let i, random;
        let result = '';
        for (i = 0; i < length; i++) {
            random = (Math.random() * 16) | 0;
            if (i === 8 || i === 12 || i === 16 || i === 20) {
                result += '-';
            }
            result += (i === 12 ? 4 : i === 16 ? (random & 3) | 8 : random).toString(16);
        }

        return result;
    }
}
