<div class="d-flex flex-column h-100">
    <header class="text-light border-bottom">
        <div class="container pt-3 mb-1 px-0 px-md-3 d-flex">
            <img src="assets/logos/hd/SVG/Logo_HD_wh.svg" height="40" width="134" class="mt-auto" />

            <div class="w-100 text-right">
                <i (click)="activeModal.close('Close click')" class="las la-times la-3x"></i>
            </div>
        </div>
    </header>

    <!-- <header class="text-light d-flex">
        <div class="w-100">Glava</div>
        <div>
            <i class="las la-times la-3x" (click)="activeModal.close('Close click')"></i>
        </div>
    </header> -->
    <div class="container h-100 align-items-stretch mt-3 overflow-auto">
        <ng-template comPopupHost></ng-template>
    </div>

    <footer class="mt-auto">
        <div class="h-100">
            <div *ngIf="!settings?.hiddePolicy" style="font-size: small; line-height: 1.2" class="text-center text-light pb-2">
                <div i18n>Storitev zagotavlja Medifit d.o.o. &middot; Različica {{ env?.VERSION }}</div>
                <div i18n>
                    <a href="https://www.medifit.si/dokumenti/splosni-pogoji-aplikacije-halo-doktor/" target="_blank" class="text-light">
                        <u>Splošni pogoji uporabe</u>
                    </a>
                    in

                    <a href="https://www.medifit.si/dokumenti/politika-zasebnosti-druzbe-medifit/" target="_blank" class="text-light">
                        <u>Politika zasebnosti družbe Medifit</u>
                    </a>
                </div>
            </div>

            <div class="bg-brand-gradient container">
                <div class="p-2 w-100">
                    <img src="assets/logos/GENERALI/generali-logo-small_WHI.svg" height="30px" class="mx-auto d-block" />
                </div>
            </div>
        </div>
    </footer>
</div>
