import {
    HttpErrorResponse,
    HttpHandler,
    HttpHeaderResponse,
    HttpProgressEvent,
    HttpRequest,
    HttpResponse,
    HttpSentEvent,
    HttpUserEvent,
    HttpXsrfTokenExtractor,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './../../_modules/public/_services/auth.service';
import { HelperService } from './../services/helper.service';
import { HttpClientService } from './../services/http-client.service';
import { BaseSelectors } from './../state/base/base.selectors';
// import { EmployerState } from '../state/employer/employer.state';

@Injectable({
    providedIn: 'root',
})
export class TokenInterceptorService {
    constructor(
        private injector: Injector,
        private tokenExtractor: HttpXsrfTokenExtractor,
        private helper: HelperService,
    ) {}

    private isRefreshingToken = false;
    tokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any> {
        // debugger;
        if (this.isOneOfIgnoreredUrl(request)) {
            return next.handle(request);
        }
        const storage = this.injector.get(Store);
        const router = this.injector.get(Router);
        let token: string = <string>storage.selectSnapshot(BaseSelectors.getToken);
        if (this.isContentHubUrl(request)) {
            token = environment.contentHubToken;
        }

        // if (!token) {
        //     token = storage.selectSnapshot(EmployerState.getToken);
        // }
        // const token = _.get(storage.getToken(), 'access_token', '');
        return next.handle(this.addTokenToRequest(request, token, '')).pipe(
            catchError(err => {
                //TODO
                // if (request.url === this.helper.getAuthUrl()) {
                //     return throwError(err);
                // }
                if (err instanceof HttpErrorResponse) {
                    switch ((<HttpErrorResponse>err).status) {
                        case 0:
                            console.error('Napaka 0 - restart servisov?');
                            return throwError(err);
                        case 401:
                            //2FA
                            if (_.get(err, 'error.error_description') === '2FA') {
                                return throwError(err);
                            }

                            router.navigate(['/public/login']);
                            return throwError(err);
                        // //TODO ni najlepsa resitev
                        // if (
                        //     _.get(request, 'body', '') &&
                        //     _.get(request, 'body', '').indexOf('refresh_token') !== -1
                        // ) {
                        //     storage.clearToken();
                        //     router.navigate(['/login']);
                        // }
                        // return this.handle401Error(request, next);
                        case 400:
                            return throwError(err);
                        case 403:
                            return throwError(err);
                        default:
                            return throwError(err);
                    }
                } else {
                    return throwError(err);
                }
            }),
        );
    }

    private isOneOfIgnoreredUrl(request: any): boolean {
        return /public/.test(request.url) || /hooks.slack.com/.test(request.url) || /token/.test(request.url);
    }
    public isContentHubUrl(request: any): boolean {
        return /content.medifit/.test(request.url);
    }

    private addTokenToRequest(request: HttpRequest<any>, token: string | null, XSRFToken: string): HttpRequest<any> {
        let useToken = true;
        if (request.body && _.isString(request.body)) {
            if (_.get(request, 'body', '').indexOf('refresh_token') !== -1) {
                useToken = false;
            }
        }
        if (!token) {
            useToken = false;
        }

        if (useToken) {
            return request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                },
            });
        } else {
            return request.clone();
        }
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        const httpClientMF = this.injector.get(HttpClientService);
        const authService = this.injector.get(AuthService);
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);

            // return httpClientMF.refreshToken().pipe(
            //     switchMap((token: any) => {
            //         const accessToken = _.get(token, 'access_token', undefined);
            //         if (accessToken) {
            //             this.tokenSubject.next(accessToken);
            //             return next.handle(this.addTokenToRequest(request, accessToken, ''));
            //         }

            //         return <any>authService.logout();
            //     }),
            //     catchError((err) => {
            //         return <any>authService.logout();
            //     }),
            //     finalize(() => {
            //         this.isRefreshingToken = false;
            //     }),
            // );
        } else {
            this.isRefreshingToken = false;

            return this.tokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {
                    return next.handle(this.addTokenToRequest(request, token, ''));
                }),
            );
        }
    }
}
